body {
    background-color: #7f7f7f;
    font-family: Arial;
    color: white;
}

html, body, .full {
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.root {
    position: relative;
    margin: 0px;
    width: 100%;
    height: 100%;
}

.body {
    position: relative;
}

.full {
    position: absolute;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.sizeauto {
    flex: 0 1 auto;
}

.sizegrow {
    flex: 1 1 1px;
}

.button {
    border: 0.25em solid #ccc;
    border-radius: 0.5em;
    display: inline-block;
    padding: 0.25em;
    background: #404040;
    opacity: 0.5;
    margin: 0.5em;
}

    .button:hover {
        opacity: 1;
    }

    .button:active {
        opacity: 0.75;
    }

    .button > img {
        width: 3em;
    }

.nomouse {
    pointer-events: none;
}

.yesmouse {
    pointer-events: auto;
}

.hidden {
    display: none;
}

.darken {
    background-color: #00000080;
}

.scrollx {
    overflow-x: auto;
    white-space: nowrap;
}

.noscrollx {
    overflow-x: hidden;
}

#canvasMain {
    position: absolute;
}

input {
    background-color: #333;
    color: white;
    border: 2px solid #333;
    border-radius: 4px;
}

[type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

[type=radio]:checked + img {
    outline: 2px solid #fff;
    margin: 2px;
}

[type=radio]:checked + span {
    outline: 2px solid #fff;
    margin: 2px;
}